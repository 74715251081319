/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import { FC } from 'react';
import { Col, Nav } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';

import { loggedUserInfoStore, sideNavStore } from '@/stores';
import { Icon } from '@/components';
import './index.scss';
import { useQueryTags } from '@/services';
import { BackModal } from './BackModal';

const Index: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user: userInfo } = loggedUserInfoStore();
  const { visible, can_revision } = sideNavStore();
  const isAdmin = userInfo?.role_id === 2;
  const isVip = userInfo.services && userInfo.services.length > 1;

  const { data: tags } = useQueryTags({
    page: 1,
    page_size: 999,
    query_cond: 'name',
  });

  const handleNavClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };
  return (
    <Col
      xl={2}
      lg={3}
      md={12}
      className={classnames(
        'main-side-nav',
        'position-relative',
        visible ? '' : 'd-none d-lg-block',
      )}
      style={{ minHeight: 'calc(100vh - 115px)' }}
      id="sideNav">
      <div className="nav-wrap pt-4">
        <Nav variant="pills" className="flex-column">
          <NavLink
            to="/questions"
            className={({ isActive }) =>
              isActive || pathname === '/' ? 'nav-link active' : 'nav-link'
            }>
            <Icon name="question-circle-fill" className="me-2" />
            <span>{t('header.nav.question')}</span>
          </NavLink>

          <Nav.Link
            href="/tags"
            active={pathname === '/tags'}
            onClick={(e) => handleNavClick(e, '/tags')}>
            <Icon name="tags-fill" className="me-2" />
            <span>{t('header.nav.tag')}</span>
          </Nav.Link>

          <NavLink to="/users" className="nav-link">
            <Icon name="people-fill" className="me-2" />
            <span>{t('header.nav.user')}</span>
          </NavLink>

          {(isVip || isAdmin) && (
            <div>
              <div className="py-2 mt-3" style={{ fontSize: '18px' }}>
                VIP板块
              </div>
              {tags?.list
                ?.filter((i) => i.auth_service)
                .map((tag) => {
                  return (
                    <NavLink
                      key={tag.slug_name}
                      to={`/tags/${tag.slug_name}`}
                      className="nav-link "
                      style={{
                        wordBreak: 'break-all',
                        marginTop: '5px',
                        position: 'relative',
                      }}>
                      <Icon name="chat-quote-fill" className="me-2" />
                      <span>{tag.display_name}</span>
                      <img
                        src="https://file.gnlab.com/app/club-vip-small.png"
                        alt="vip"
                        style={{
                          width: '20px',
                          height: '16px',
                          position: 'absolute',
                          right: '8px',
                          top: '12px',
                        }}
                      />
                    </NavLink>
                  );
                })}
            </div>
          )}

          <div>
            <div className="py-2 mt-3" style={{ fontSize: '18px' }}>
              免费板块
            </div>
            {tags?.list
              ?.filter((i) => !i.auth_service)
              .map((tag) => {
                return (
                  <NavLink
                    key={tag.slug_name}
                    to={`/tags/${tag.slug_name}`}
                    className="nav-link "
                    style={{ wordBreak: 'break-all', marginTop: '5px' }}>
                    <Icon name="chat-quote-fill" className="me-2" />
                    <span>{tag.display_name}</span>
                  </NavLink>
                );
              })}
          </div>

          {can_revision || userInfo?.role_id === 2 ? (
            <>
              <div className="py-2 mt-4" style={{ fontSize: '18px' }}>
                管理
              </div>

              {userInfo?.role_id === 2 ? (
                <NavLink to="/admin" className="nav-link mt-5px">
                  <Icon name="house-gear-fill" className="me-2" />
                  <span>后台管理</span>
                </NavLink>
              ) : null}
            </>
          ) : null}
        </Nav>
        <BackModal />
      </div>
      <div className="side-nav-right-line"></div>
    </Col>
  );
};

export default Index;
