import { FC, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import './index.scss';

const BackModal: FC = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="slide-service">
        <div
          style={{
            cursor: 'pointer',
            width: '175px',
            height: '40px',
            lineHeight: '40px',
            textAlign: 'center',
            background: '#ECF0FF',
            color: '#1677FF',
            fontSize: '14px',
            borderRadius: '20px',
          }}
          onClick={handleShow}>
          远程协助
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>技术远程协助</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '20px 0',
              fontSize: '16px',
              lineHeight: '25px',
              color: 'rgba(0, 0, 0, 0.65)',
              zIndex: 1,
            }}>
            <img
              src="https://file.gnlab.com/haiboSimple.png"
              alt="企业微信联系人"
              style={{ width: '55%', marginBottom: '24px' }}
            />
            <span>远程协助需与工程师预约时间</span>
            <span>由于需要提前了解您的情况</span>
            <span>请先在本论坛发帖描述问题</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { BackModal };
